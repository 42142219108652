import React from 'react';
import './Error.css';
import './navBar.css';
import NavigationBar from './NavigationBar';

function Error() {
    return (
        <div className="App1">
            <div className="BackgroundError">
<NavigationBar />
            </div>
        </div>
    )
}

export default Error
