import React from 'react';
import './navBar.css';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import MenuIcon from '@mui/icons-material/Menu';


function NavigationBar() {
    return (
            <nav>
                <a className="ReturnHome" href="/" aria-label="Return Home"> </a>
                <a className="NavAbout" href="/About" aria-label="Link to About Page"><h1>About</h1></a>
                <a className="NavStore" href="/Store" aria-label="Link to Store"><h1>Store</h1></a>
                <a className="NavStratoLab" href="/StratoLab" aria-label="Link to StratoLab"><h1>StratoLab</h1></a>
                <a className="NavOpen" href="/3U" aria-label="Link to Opensource 3U"><h1>OpenSource-3U</h1></a>
                <a className="NavTeam" href="/Team" aria-label="Link to Team"><h1>Team</h1></a>
                <a className="NavApply" href="/Apply" aria-label="Link to Apply"><h1>Apply</h1></a>
                <a className="NavContact" href="/Contact" aria-label="Link to Contact Page"><h1>Contact</h1></a>
                <a className="NavResources" href="/Resources" aria-label="Link to Resource"><h1>Resources</h1></a>
                <Dropdown>
                  <MenuButton className="DropDownButton">
                      <MenuIcon className="MenuSymbol" style={{ fontSize: '48px' }} ></MenuIcon>
                  </MenuButton>
                  <Menu>
                    <div className="MenuBarDiv">
                      <a className="NavAboutBar" href="/About" aria-label="Link to About Page"><h1>About</h1></a>
                      <a className="NavStoreBar" href="/Store" aria-label="Link to Store" ><h1>Store</h1></a>
                      <a className="NavStratoLabBar" href="/StratoLab" aria-label="Link to StratoLab" ><h1>StratoLab</h1></a>
                      <a className="NavOpenBar" href="/3U" aria-label="Link to Opensource 3U"><h1>OpenSource-3U</h1></a>
                      <a className="NavTeamBar" href="/Team" aria-label="Link to Team"><h1>Team</h1></a>
                      <a className="NavApplyBar" href="/Apply" aria-label="Link to Apply"><h1>Apply</h1></a>
                      <a className="NavContactBar" href="/Contact" aria-label="Link to Contact Page"><h1>Contact</h1></a>
                      <a className="NavResourcesBar" href="/Resources" aria-label="Link to Resource"><h1>Resources</h1></a>
                    </div>
                  </Menu>
                </Dropdown>
          </nav>
    )
}

export default NavigationBar
