import React from 'react';
import './Store.css';
import './navBar.css';
import NavigationBar from './NavigationBar';

function Store() {
    return (
        <div className="Store1">
            <div className="BackgroundStore">
<NavigationBar />
        <h1>Under Construction</h1>  
            </div>
        </div>
    )
}

export default Store
