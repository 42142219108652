import React from 'react';
import './Team.css';
import './navBar.css';
import NavigationBar from './NavigationBar';

function Team() {
    return (
        <div className="Team">
            <div className="BackgroundTeam">
<NavigationBar />
        <div className="TeamFlex">
          <img className="Alexander" src="https://alexanderbuick.com/static/media/AlexanderHelium.1ec9fbe1c0b9b5ab11d2.jpeg" alt="Alexander Buick" />
          <div className="AlexanderDescription">
            <div className="AlexanderName">Alexander Buick</div>
            <div className="AlexanderTitle">Founder (2022 - Present)</div>
<h4>Alexander Buick Founded Mission Cube in November of 2022 with a goal of developing a fully opensource CubeSat.  Alexander is currently </h4> 
</div>
          </div>
            </div>
        </div>
    )
}

export default Team
