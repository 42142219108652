import './App.css';
import './navBar.css';

import NavigationBar from './NavigationBar';

function App() {
  return (
    <div className="App">
      <div className="Background">
        <NavigationBar />
          <div className="StratoDiv" >
            <a href="https://youtu.be/NQqaMOXl9LY?si=-QHy8S27qUL1gm3S" rel="noreferrer" target="_blank">
            <h1 className="StratoLab" >StratoLab One</h1>
            </a>
          </div>
      </div>
      <div className="Description">
      </div>
      <div className="lowerHome" >
        <div className="Quote">
        <h1 className="lowerQuote">“Somewhere, something incredible is waiting to be known.” </h1>
        <h1 className="lowerQuoteCredit">-Carl Sagan</h1>
        </div>
        <nav><h6 className="lowerCreditImage"><a href='https://www.nasa.gov/image-feature/apollo-8-earthrise' rel="noreferrer" target="_blank">Image Credit: <em>NASA</em></a></h6></nav>
      </div>
    </div>
  );
}

export default App;
