import React from 'react';
import './3U.css';
import './navBar.css';

import NavigationBar from './NavigationBar';


function CubeSat3U () {
    return (
        <div className="Open1">
            <div className="BackgroundOpen">
<NavigationBar />
<div>Hello</div>
            </div>
        </div>
    )
}

export default CubeSat3U
