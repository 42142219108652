import React from 'react';
import './About.css';
import './navBar.css';
import NavigationBar from './NavigationBar';

function About() {
    return (
        <div className="About1">
            <div className="BackgroundAbout">
              <NavigationBar />
        <h1>Under Construction</h1>
            </div>
        </div>
    )
}

export default About
