import React from 'react';
import './StratoLab.css';
import './navBar.css';
import NavigationBar from './NavigationBar';

function StratoLab() {
    return (
        <div className="StratoLab1">
            <div className="BackgroundStratoLab">
              <NavigationBar></NavigationBar>
        <h1>Under Construction</h1>
            </div>
        </div>
    )
}

export default StratoLab
