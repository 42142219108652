import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Error from './Error';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './About';
import StratoLab from './StratoLab';
import OpenSource3U from './3U';
import Store from './Store';
import Apply from './Apply';
import Contact from './Contact';
import { Helmet } from 'react-helmet';
import Favicon from './Favicon.svg';
import Team from './Team';



function TheApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<Error />}></Route>
        <Route path="/About" element={<About />}></Route>
        <Route path="/Store" element={<Store />}></Route>
        <Route path="/StratoLab" element={<StratoLab />}></Route>
        <Route path="/OpenSource3U" element={<OpenSource3U />}></Route>
        <Route path="/3U" element={<OpenSource3U />}></Route>
        <Route path="/Apply" element={<Apply />}></Route>
        <Route path="/Contact" element={<Contact />}></Route>
        <Route path="/Team" element={<Team />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <TheApp />
    <Helmet>
  <link
    rel="icon"
    type="image/png"
    sizes="32x32"
    href={Favicon}
  />
</Helmet>

  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
