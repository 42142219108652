import React from 'react';
import './Apply.css';
import './navBar.css';
import NavigationBar from './NavigationBar';
import Applyform from './Applyform';


function Apply() {
    return (
        <div className="Apply1">
            <div className="BackgroundApply">
<NavigationBar />
<Applyform />
            </div>
        </div>
    )
}

export default Apply
